import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-four'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader/caseheader'
import CaseStudyArea from '../containers/global/case-study-area'
import CTAArea from '../containers/global/cta-area/section-two'

const CaseStudiesPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO 
      title="Case Studies"
      description="See how we helped over 35 companies to solve their most complex technology challenges."
    />
    <Header/>
    <PageHeader 
        pageContext={pageContext} 
        location={location}
        title="Case Studies"
    />
    <main className="site-wrapper-reveal">
        <CaseStudyArea/>
        <CTAArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default CaseStudiesPage
 